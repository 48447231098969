<template>
	<div>
		<div class="row">
			<div class="col-12">
				<button
					class="btn btn-export float-right"
					@click.prevent="downloadReport"
					:disabled="!isCustomerRatingsExist"
					>
					<span
						><svg
							width="12"
							height="12"
							viewBox="0 0 12 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							>
							<path
								d="M7.33333 1.33333C6.96514 1.33333 6.66667 1.03486 6.66667 0.666667C6.66667 0.298477 6.96514 0 7.33333 0H11.3333C11.5101 0 11.6797 0.070238 11.8047 0.195262C11.9298 0.320287 12 0.489856 12 0.666667L12 4.66667C12 5.03486 11.7015 5.33333 11.3333 5.33333C10.9651 5.33333 10.6667 5.03486 10.6667 4.66667L10.6667 2.27614L4.4714 8.4714C4.21106 8.73175 3.78894 8.73175 3.5286 8.4714C3.26825 8.21105 3.26825 7.78894 3.5286 7.52859L9.72386 1.33333H7.33333ZM0 2.66667C0 1.93029 0.596954 1.33333 1.33333 1.33333H4.66667C5.03486 1.33333 5.33333 1.63181 5.33333 2C5.33333 2.36819 5.03486 2.66667 4.66667 2.66667H1.33333V10.6667H9.33333V7.33333C9.33333 6.96514 9.63181 6.66667 10 6.66667C10.3682 6.66667 10.6667 6.96514 10.6667 7.33333V10.6667C10.6667 11.403 10.0697 12 9.33333 12H1.33333C0.596954 12 0 11.403 0 10.6667V2.66667Z"
								fill="#4848ED"
								/>
						</svg>
					</span>
					Export
				</button>
			</div>
		</div>

		<div class="row mt-4">
			<div class="col-12">
				<div class="card">
					<div class="card-header d-flex justify-content-end">
						<div class="row">
							<div class="col-12 my-2">
								<div>
									<v-datepicker
										class="float-right"
										v-model="filter.range"
										placholder="Filter by date"
										range
										></v-datepicker>
								</div>
							</div>
						</div>
					</div>

					<b-table
						striped
						hover
						selectable
						responsive
						show-empty
						:items="customerRatings"
						:fields="fields"
						:current-page="currentPage"
						:busy="fetchingCustomerRatings"
						>
						<template #table-busy>
							<div class="text-center text-secondary my-2">
								<strong>Loading...</strong>
							</div>
						</template>
						<template #empty>
							<div class="text-center">No customer rating record found</div>
						</template>
						<template #cell(date_created)="data">
							{{ data.item.date_created | date('dd/MM/yyyy') }}
						</template>

						<template #cell(star)="data">
							<star-rating
								:rated-stars="parseInt(data.item.star)"
								></star-rating>
						</template>
						<template #cell(issues)="data">
							<span v-if="data.item.issues.length > 0">
								{{ data.item.issues.map(issue => issue.name).join(', ') }}</span
								>
							<span v-else> {{ 'None' }}</span>
						</template>
						<template #cell(attachment_urls)="data">
							<span
								v-if="data.item.attachment_urls.length > 0"
								class="pointer"
								@click="downloadAttachments(data.item.attachment_urls)"
								>
								<u
									>{{
										data.item.attachment_urls.length
									}}
									attachment{{
										data.item.attachment_urls.length > 1 ? 's' : ''
									}}</u
									>
							</span>
							<span v-else>{{ 'None' }}</span>
						</template>
					</b-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import StarRating from '@/components/core/StarRating.vue'
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'
import {defineProps, ref, watch, computed} from 'vue'
import Swal from 'sweetalert2'
import {axiosInstance as axios} from '@/plugins/axios'

const props = defineProps({ vehicleId: [String, Number] })

const driver = ref(null)
const fields = ref([
        {
          key: 'date_created',
          label: 'Date'
        },
        {
          key: 'passenger_name',
          label: 'Passenger Name'
        },
        {
          key: 'star',
          label: 'Rating'
        },
        {
          key: 'comment',
          label: 'Comment'
        },
        {
          key: 'issues',
          label: 'Issues'
        },
        {
          key: 'attachment_urls',
          label: 'Attachment(s)'
        },
        {
          key: 'trip_time',
          label: 'Trip Time'
        },
        {
          key: 'route',
          label: 'Route'
        }
      ])
const perPage = ref(20)
const currentPage = ref(1)
const totalRecords = ref(0)
const totalPages = ref(0)
const customerRatings = ref(null)
const filter = ref({
        range: ref([])
      })
const fetchingCustomerRatings = ref(true)


const dateRange = computed(() =>  {
      return filter.value.range.map((date) => moment(date).format('YYYY-MM-DD'))
    })
const dateFilterValid = computed(() => {
      return (
        filter.value.range.length &&
        filter.value.range.filter((date) => !!date).length > 0
      )
    })
const isCustomerRatingsExist = computed(() => {
      return customerRatings.value?.length
    })


watch(currentPage, () => {
  fetchCustomerRatings()
})

watch(dateFilterValid, () => {
  fetchCustomerRatings(true)
    })



    const downloadAttachments  = (urls) => {
      for (const url of urls) {
        window.open(url)
      }
    }
  const fetchCustomerRatings  = async (reset = false) => {
      if (reset) {
        currentPage.value = 1
      }

      fetchingCustomerRatings.value = true

      const startDate = dateRange.length ? dateRange[0] : ''
      const endDate = dateRange.length ? dateRange[1] : ''

      axios
        .get(
          `/v1/ratings/vehicles/${props.vehicleId}?limit=${perPage.value}&page=${currentPage.value}&creation_date_start=${startDate}&creation_date_end=${endDate}`
        )
        .then((res) => {
          customerRatings.value = res.data.data
          totalRecords.value = res.data?.metadata?.total
        })
        .finally(() => (fetchingCustomerRatings.value = false))
    }

    const downloadReport  = async () => {
      const csvData = customerRatings.value.map((x) => {
        return {
          date_created: moment(x.date_created).format('YYYY-MM-DD'),
          passenger_name: x.passenger_name,
          star: x.star,
          comment: x.comment,
          issues: x.issues.map((issue) => issue.name).join(', '),
          attachment_urls: x.attachment_urls.join(', '),
          trip_time: x.trip_time,
          route: x.route
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Vehicle Customer Ratings',
        useTextFile: false,
        useBom: true,
        headers: [
          'Date Created',
          'Passenger Name',
          'Rating',
          'Comment',
          'Issues',
          'Attachments',
          'Trip Time',
          'Route'
        ]
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)

      Swal.fire({
        icon: 'success',
        title: 'Ratings Report downloaded',
        text: 'Customer Ratings Report has been downloaded successfully',
        showCloseButton: true
      })
    }
    fetchCustomerRatings()
</script>

<style scoped>
.btn-export {
  background-color: #ffffff;
  color: #4848ed;
}
</style>
